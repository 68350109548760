<template>
      <v-app class>
        <div>
             <v-alert
      v-model="alert"
      border="left"
      dark
      dismissible
      class="alert"
      :class="{'alert-error':alertType=='error', 'alert-success':alertType=='success'}"
    >
    {{alertMessage}}
    </v-alert>
        </div>
        <div class="row">
          <div class="col-md-6 ml-auto mr-auto">
            <v-container>
              <div class="mb-8 mt-8 text-center">
                <h1> Edit Contractor Employer </h1>
              </div>
              <v-form ref="contractEmployer" v-model="valid">
                <v-row no-gutters>
                  <v-col cols="12">
                    <TextField v-model="formData.name" :label="'Name'" />
                  </v-col>
                  <v-col cols="12">
                    <TextField v-model="formData.email" :label="'Email'" :type="'email'" />
                  </v-col>
                  <v-col cols="12">
                    <TextField v-model="formData.phone" :label="'Phone Number'" :type="'text'" />
                  </v-col>
                  <v-col cols="12">
                    <TextField v-model="formData.url" :label="'URL'" :type="'text'" />
                  </v-col>
                  <v-col cols="12">
                    <v-textarea
                      outlined
                      auto-grow="false"
                      label="Address"
                      v-model="formData.address"
                    ></v-textarea>
                  </v-col>
                  <v-col cols="12" class="d-flex justify-end">
                    <div class="btn-group">
                      <Button
                      :label="'Update'"
                      :btnType="'Submit'"
                      @onClick="update"
                      :disabled="!valid"
                      :isLoading="isSavingUser"
                      class="mr-4"
                    />
                    <Button :label="'Reset'" :btnType="'Cancel'" @onClick="onReset" />
                    </div>
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
          </div>
        </div>
      </v-app>
</template>
<script>
import TextField from '@/components/ui/form/TextField.vue'
import Button from '@/components/ui/form/Button.vue'

import { contractEmployerService } from '@/services'
// import { UPDATE_CONTRACTEMPLOYER } from '@/store/action-type'

export default {
  components: {
    TextField,
    Button

  },
  data () {
    return {
      search: '',
      formData: {
        name: '',
        email: '',
        action: 'Edit',
        status: 0
      },
      id: '',
      valid: false,
      isSavingUser: false,
      alertMessage: '',
      alert: false,
      alertType: ''
    }
  },
  computed: {

  },
  methods: {
    showAlertMessage (message, type) {
      this.alertMessage = message
      this.alert = true
      this.alertType = type
    },
    update () {
      this.isSavingUser = true
      // const payload = [
      //   {
      //     'op': 'replace',
      //     'path': '/name',
      //     'value': this.formData.name
      //   },
      //   {
      //     'op': 'replace',
      //     'path': '/email',
      //     'value': this.formData.email
      //   }
      // ]

      contractEmployerService.updateContractEmployer(this.id, this.formData).then(() => {
        this.isSavingUser = false
        this.showAlertMessage(
          'Contractor Employer updated successful',
          'success'
        )
        this.onReset()
      }).catch(error => {
        console.log(error)
        this.showAlertMessage('Contractor Employer creation failed', 'error')
      }).finally(() => {
        this.isSavingUser = false
        // this.onReset()
      })
      // this.$store
      //   .dispatch(UPDATE_CONTRACTEMPLOYER, this.id, payload)
      //   .then(() => {
      //     this.isSavingUser = false
      //     this.showAlertMessage(
      //       'Contractor Employer updated successful',
      //       'success'
      //     )
      //   })
      //   .catch((error) => {
      //     console.log(error)
      //     this.showAlertMessage('Contractor Employer creation failed', 'error')
      //   })
      //   .finally(() => {
      //     this.isSavingUser = false
      //     this.onReset()
      //   })
    },
    onReset () {
      this.$refs.contractEmployer.reset()
    }
  },
  mounted () {
    contractEmployerService.editContractEmployer(this.$route.params.id).then((result) => {
      console.log(result)
      const data = result.data
      this.formData.name = data.name
      this.formData.email = data.email
      this.id = data.id
    }).catch(error => {
      console.log('this Error', error)
      this.showAlertMessage(error.message, 'error')
    })
  }
}
</script>
<style scoped>
.search-btn {
  background-color: #e6e3e3;
  height: 40px;
  border-radius: 3px !important;
}
.btn-group{
  display: inline-flex;
}
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
.alert {
  position: fixed;
  z-index: 9999;
  top: 5px;
  min-width: 50%;
}
.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}
.alert-error {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}
</style>
